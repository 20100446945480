import React from 'react';
import './aboutSectionHome.scss';

import { NavLink } from 'react-router-dom';

const AboutSection = () => (
    <div className="about">
        <h2>Quem somos?</h2>
        <p>Somos uma empresa de rastreamento que proporciona <strong>segurança,</strong>
        <strong>praticidade, qualidade e tecnologia</strong> de última geração no serviço de
        <strong> localização, bloqueio total e telemetria...</strong><NavLink exact to="/about">Ler mais</NavLink></p>
    </div>
);

export default AboutSection;