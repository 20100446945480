import React from 'react';
import './aplication.scss';

import NavBar from '../../components/navBar/';
import FooterAplication from '../../components/footers/footerAplication/';

const Aplication = () => (
    <div className="page-aplication">
        <NavBar classHeader="mbot" />
        <div className="container">
          <div className="content__page-aplication">
            <h1>
              Localização na palma da sua mão
            </h1>
            <p>
              O equipamento instalado no seu veículo ou carga e também o
              sistema de rastreamento foram desenvolvidos com as tecnologias
              mais avançadas para a coleta de dados, incluindo a comunicação
              das informações das zonas de sombras, processamento e armazenamento
              de dados sobre o bem rastreado.
            </p>
            <br/><br/><br/>
            <p>
              Tudo isso para aumentar a segurança do seu patrimônio ou
              a eficiência no seu negócio, pela análise dos dados armazenados
              sobre o desempenho de sua frota.
            </p>
          </div>
        </div>
        <FooterAplication />
    </div>
);

export default Aplication;