import React from 'react'

import './style.scss'
import { NavLink } from 'react-router-dom'

const PlansGrid = () => (
    <div className="plans-flex">
        <NavLink className="plans-item" to="plans" exact>FAMILIAR</NavLink>
        <NavLink className="plans-item" to="plans" exact>FROTA</NavLink>
        <NavLink className="plans-item" to="plans" exact>INDIVIDUAL</NavLink>
    </div>
)

export default PlansGrid