import React from 'react'

import './style.scss'
import { NavLink } from 'react-router-dom';
// import botaoNovo from '../../assets/images/BOTAO-NOVO.png'

const ApresentationValue = () => (
    <div className="apresentation-value">
        {/*<NavLink className="apresentation-image" exact to="contact"></NavLink>*/}
    </div>
)

export default ApresentationValue