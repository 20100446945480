import React from 'react'
import './style.scss'


import {NavLink} from 'react-router-dom'

const ApresentationPlans = () => (
    <div className="apresentation-plans">
        <div className="apresentation-plans-item">
            <NavLink className="plan-item" to="plans" exact>FAMILIAR</NavLink>
            <div className="apresentation-plans-text">
                Rastreie os veículos da sua família e tenha tranquilidade no seu dia a dia protegido de roubo/furto. - <strong>A partir de 4 veículos</strong> -
            </div>
        </div>
        <div className="apresentation-plans-item">
            <NavLink className="plan-item" to="plans" exact>FROTA</NavLink>
            <div className="apresentation-plans-text">
                Rastreie os veículos de sua empresa e tenha otimização de custos, controle da frota, maior produtividade e segurança. - <strong>A partir de 5 veículos</strong> -
            </div>
        </div>
        <div className="apresentation-plans-item">
            <NavLink className="plan-item" to="plans" exact>INDIVIDUAL</NavLink>
            <div className="apresentation-plans-text">
                Rastreie o seu veículo e tenha tranquilidade no dia a dia, protegido de roubo/furto e no controle do seu veículo. - <strong>A partir de 1 veículo</strong> -
            </div>
        </div>
    </div>
)


export default ApresentationPlans