import React from 'react';
import './about.scss';

import NavBar from '../../components/navBar/';
import Button from '../../components/btnGlobal/';

const About = () => (
        <div className="page-about">
            <NavBar classHeader="mbot"/>
            <div className="container">
                <div className="content__page-about">
                    <div className="text-about">
                        <h1>Quem somos?</h1>
                        <p>Somos uma empresa de rastreamento que proporciona <b>segurança,
                        praticidade, qualidade e tecnologia </b> de última geração no serviço de
                        <b> localização, bloqueio total e telemetria</b> dos veículos. Tudo isso
                        aliado ao melhor equipamento e infra-estrutura de rastreamento.</p>
                    </div>
                </div>
            </div>
            <div className="features-about">
                <div className="text__features-about">
                    <p>Cercas eletrônicas e controle de pontos com alertas por e-mail e aplicativo.</p>
                    <p>Central de emergência com atendimento 24h.</p>
                    <p>Relatórios completos para controle de frotas.</p>
                    <p>Sistema com bateria backup e controle de consumo de energia.</p>
                    <p>Localização via satélite com precisão de até 1 minuto.</p>
                    <p>Trajetos e rotas no mapa com histórico de 1 ano</p>
                    <p>Atendimento de emergência pelo 0800.</p>
                    <p>Controle de manutenções e revisões com alertas por e-mail.</p>
                    <p>Controle total de velocidades excedidas e condução perigosa.</p>
                    <Button btnStyle="highlight" redirect="/contact" text="Tenho interesse"/>
                </div>
            </div>
        </div>
);

export default About;