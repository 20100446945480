import React from 'react';
import './apresentationHome.scss';

// import SvgAnimated from '../animatedSvg/';
import Button from '../../components/btnGlobal/';

import carImage from '../../assets/images/car.png';




const ApresentationHome = () => (
    <div className="description grid-column-2">
        <div className="app-view">
            <div
                className="animated-image-car"
            >
                <img src={carImage} alt="" />
            </div>
        </div>
        <div className="description-text align-self-end">
            <div className="text">
                <h1>Esteja um passo a frente</h1>
                <p>Com o rastreamento veicular você
                    sempre sabe onde o seu veículo está.
                    Em caso de roubo ou furto, rapidamente
                    ele é localizado e recuperado.
                </p>
                <Button btnStyle="highlight" redirect="/contact" text="Tenho interesse"/>
            </div>
        </div>
    </div>
);

export default ApresentationHome;