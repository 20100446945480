import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Pages
import Home from './view/home/';
import Aplication from './view/aplication/';
import Plans from './view/plans/';
import About from './view/about/';
import Contact from './view/contact';
import Access from './view/access';
// https://medium.com/@khwsc1/step-by-step-guide-of-simple-routing-transition-effect-for-react-with-react-router-v4-and-9152db1566a0
// https://medium.com/@dave_lunny/animating-css-gradients-using-only-css-d2fd7671e759



class App extends React.Component {
  render () {
    return (
      <React.Fragment>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/aplication" component={Aplication} />
              <Route exact path="/plans" component={Plans} />
              <Route exact path="/about" component={About} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/access" component={Access} />
            </Switch>
          </Router>
      </React.Fragment>
    );
  }

} 

export default App;
