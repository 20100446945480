import React, { useState }from 'react';
import './navBar.scss';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/new_logo.png';
import LogoX475 from '../../assets/images/logo_xxx.png';
import LogoMobileMenu from '../../assets/images/logo_xxx.png';
import Icon from '@mdi/react';
import { mdiMenu, mdiFacebook, mdiInstagram, mdiWhatsapp, mdiClose } from '@mdi/js';


import warning from '../../assets/images/Warning.png'

const NavBar = ({classHeader}) => {
    const [actMenu, setActMenu] = useState();
    const [actOverlay,  setActOverlay] = useState();

    const mobilePropietsAct = () => {
        setActMenu(true)
        setActOverlay(true)
    }
    const mobilePropietsDst = () => {
        setActMenu(false)
        setActOverlay(false)
    } 
    return (
    <header className={classHeader}>
        <div className="msg0800">
            <img src={warning} alt="0800"/>
            <span>EMERGÊNCIA LIGUE 0800 941 5490</span>
        </div>
        <nav>
            <div className="container">
                <div className="grid-contain">
                    <div className="logo">
                        <NavLink exact to="/">
                            <picture>
                                <source media="(max-width:768px)" srcSet={LogoX475} />
                                <img src={Logo} alt="logo"/>
                            </picture>
                        </NavLink>
                    </div>
                    <div className="options">
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/aplication" >
                            <span>Aplicativo</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/plans" >
                            <span>Planos</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/about" >
                            <span>Sobre nós</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/contact" >
                            <span>Contato</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/access" >
                            <span>Acesso</span>
                        </NavLink>
                        <a className="nav-link" activeClassName="active-link" href="https://acesso.getrastreamento.com.br" >
                            <span>Acesso 2</span>
                        </a>
                        <a className="nav-link" activeClassName="active-link" href="https://financeiro.getrastreamento.com.br" >
                            <span>Financeiro</span>
                        </a>
                        {/*
                        <a className="nav-link"  href="https://sistema.getrak.com/gtrastreamento" >
                            Acesso RS
                        </a>
                        <a className="nav-link"  href="https://sistema.getrak.com/gtscrastreamento" >
                            Acesso SC
                        </a>
                        */}
                    </div>
                    <Icon path={mdiMenu} color="white" className="btn-active-menu" onClick={mobilePropietsAct}/>
                </div>
                <div className={`overlay overlay-mobile ${actOverlay ? 'showOverlay' : 'hideOverlay'}`} onClick={mobilePropietsDst}/>
                <div className={`menu-mobileShadow ${actMenu && 'act-menu'}`} />
                <div className={`menu-mobile grid ${actMenu && 'act-menu'}`}>
                    <div className="logo-mobile flex justify-content-between">
                        <Icon path={mdiClose} size={1} color="#fff" onClick={mobilePropietsDst}/>
                        <NavLink exact to="/">
                            <img src={LogoMobileMenu} alt="logo-mobile"/>
                        </NavLink>
                    </div>
                    <div className="options-mobile grid justify-content-center align-self-start row-g-1">
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/" >
                            <span>Ínicio</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/aplication" >
                            <span>Aplicativo</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/plans" >
                            <span>Planos</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/about" >
                            <span>Sobre nós</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/contact" >
                            <span>Contato</span>
                        </NavLink>
                        <NavLink className="nav-link" activeClassName="active-link" exact to="/access" >
                            <span>Acesso</span>
                        </NavLink>
                        <a className="nav-link" activeClassName="active-link" href="https://acesso.getrastreamento.com.br" >
                            <span>Acesso 2</span>
                        </a>
                        <a className="nav-link" activeClassName="active-link" href="https://financeiro.getrastreamento.com.br" >
                            <span>Financeiro</span>
                        </a>
                    </div>
                    <div className="footer-menu-mobile flex justify-content-end align-self-end">
                        <Icon path={mdiFacebook} size={1} color="#fff" />
                        <Icon path={mdiInstagram} size={1} color="#fff" />
                        <Icon path={mdiWhatsapp} size={1} color="#fff" />
                    </div>
                </div>
            </div>
        </nav>
    </header>
    );
}

export default NavBar;