import React from 'react';
import './footerContact.scss';

import {WppNumberRS, WppNumberSC, WppNumberPR, WppNumberSP} from '../../wppNumber/';
import SocialMedia from '../../socialMedia/';

const FooterContact = () => (
    <footer className="footer__page-contact flex align-items-end"> 
        <div className="container">
            <div className="footer-container">
                <div className="phones__medias flex justify-content-around flex-wrap">
                    <WppNumberRS/>  
                    <WppNumberSC/>
                    <WppNumberPR/>
                    <WppNumberSP/>
                </div>
                <SocialMedia className="justify-content-center" />
            </div>
        </div>
    </footer>
)

export default FooterContact;