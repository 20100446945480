import React from 'react';
import  './plans.scss';

import NavBar from '../../components/navBar/';
// import Button from '../../components/btnGlobal/';

import FooterPlans from '../../components/footers/footerPlans/';

import ApresentationValue from '../../components/apresentationValue/'
import ApresentationImage from '../../components/apresentationImages/'
import ApresentationPlans from '../../components/apresentationPlans';

const Plans = () => (
    <div className="page-plans-new">
        <NavBar classHeader="mbot" />
        <div className="container">
            <ApresentationValue/>
            <ApresentationImage/>
            <ApresentationPlans/>
        </div>
        <FooterPlans />     
    </div>
);

export default Plans;