import React from 'react';
import './wppNumber.scss';

import WhatsAppImg from '../../assets/images/whatsapp.png';

const WppNumber = ({number, text}) => (
    <a className="phone-number align-items-center flex row"  href={`https://api.whatsapp.com/send?phone=55${number}`}>
            <img src={WhatsAppImg} alt="WhatsApp"/>
            <p>{text}</p>
    </a>
)

export const WppNumberRS = () => (<WppNumber number="(51) 98055-0250" text="Rio Grande Do Sul"/>);
export const WppNumberSC = () => (<WppNumber number="(48) 2132-7808" text="Santa Catarina"/>);
export const WppNumberPR = () => (<WppNumber number="(51) 99549-4995" text="Paraná"/>);
export const WppNumberSP = () => (<WppNumber number="(15) 98120-5288" text="São Paulo"/>);

export default WppNumber;
