import React from 'react';
import './home.scss';

import NavBar from '../../components/navBar/';
import Apresentation from '../../components/apresentationHome/';
import AboutSection from '../../components/aboutSectionHome/';
import FooterHome from '../../components/footers/footerHome/';

import ApresentationValue from '../../components/apresentationValue/'
import PlansGrid from '../../components/plansGrid/'


const Home = () => (
    <div className="page-home">
        
        <NavBar classHeader="mbot-low" />
        <div className="container">
            <ApresentationValue />
            <Apresentation />
            <AboutSection />
            <PlansGrid/>
        </div>
        <FooterHome />
     </div>
);

export default Home;