import React from 'react';
import './footerAplication.scss';

import ScreenApp from '../../screenApp/';

import ScreenImgOne from '../../../assets/images/GCONNECT.png';
import ScreenImgTwo from '../../../assets/images/GCONNECT2.png';
import ScreenImgThree from '../../../assets/images/GCONNECT3.png';
import ScreenImgFour from '../../../assets/images/GCONNECT4.png';

const FooterAplication = () => (
    <footer className="footer__page-aplication">
        <div className="container">
            <div className="footer__page grid-column-4">
                <ScreenApp image={ScreenImgOne} text="Programe alertas e avisos" />
                <ScreenApp image={ScreenImgTwo} text="Armazene dados relacionados a consumo de combustível e manutenção de peças" />
                <ScreenApp image={ScreenImgThree} text="Acompanhe em tempo real qualquer movimentação" />
                <ScreenApp image={ScreenImgFour} text="Acione a central com apenas alguns cliques" />
            </div>
        </div>
    </footer>
);

export default FooterAplication;