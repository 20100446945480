import React from 'react';
import './footerPlans.scss';

import WppNumber from '../../wppNumber/';
import SocialMedia from '../../socialMedia/';
import Button from '../../btnGlobal/';
import {WppNumberRS, WppNumberSC, WppNumberPR, WppNumberSP} from '../../wppNumber/';

const FooterPlans = () => (
    <footer className="footer__page-contact flex align-items-end">
        <div className="container">
            <div className="footer-container">
                <div className="phones__medias flex justify-content-around flex-wrap">
                    <WppNumberRS/>  
                    <WppNumberSC/>
                    <WppNumberPR/>
                    <WppNumberSP/>
                </div>
                <SocialMedia className="justify-content-center" elementInTop={
                    <Button btnStyle="highlight" text="Solicite cotação" redirect="/contact"/>
                } />
            </div>
        </div>
    </footer>
)

export default FooterPlans;