import React, { useRef } from 'react';
import './access.scss';

import NavBar from '../../components/navBar/';
import FooterHome from '../../components/footers/footerHome/';
import Btn from '../../components/btnGlobal';


function navigateTo (accesslink) {
    console.log('accesslink: ', accesslink);
    window.location.href = accesslink;
}

const Home = () => {
    return (
        <div className="page-home">
            <NavBar classHeader="mbot-low" />
            <div className="container">
                <h1>Selecione seu Estado</h1>
                <div ref={(ref) => ref ? ref.scrollIntoView() : null} className="states-row">
                    <Btn text="RS" onClick={()=>navigateTo('https://sistema.getrak.com/gtrastreamento')}   />
                    <Btn text="SC" onClick={()=>navigateTo('https://sistema.getrak.com/gtscrastreamento')}   />
                    <Btn text="PR" onClick={()=>navigateTo('https://sistema.getrak.com/gtrastreamento')}   />
                    <Btn text="SP" onClick={()=>navigateTo('https://sistema.getrak.com/gtrastreamento')} />
                </div>
            </div>
            <FooterHome />
        </div>
    );
}

export default Home;