import React from 'react';
import './btnGlobal.scss';

import { NavLink } from 'react-router-dom';

const BtnGlobal = ({text, link, redirect, btnStyle, onClick}) => (
        <React.Fragment>
        {
            redirect ? 
                <NavLink className={btnStyle==='highlight' ? "btn-highlight" : "btn-global"} exact to={redirect}>
                    <span>{text}</span>
                </NavLink>
            : link ? 
                <a className={btnStyle==='highlight' ? "btn-highlight" : "btn-global"} href={link} rel="noopener noreferrer" target="_blank">
                    <span>{text}</span>
                </a>
            :   <button className={btnStyle==='highlight' ? "btn-highlight" : "btn-global"} onClick={onClick}>
                    <span>{text}</span>
                </button>
        }
        </React.Fragment>
);

export default BtnGlobal;