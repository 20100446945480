import React from 'react';
import './footerHome.scss';

import WppNumber from '../../wppNumber';
import SocialMedia from '../../socialMedia/';
import {WppNumberRS, WppNumberSC, WppNumberPR, WppNumberSP} from '../../wppNumber/';

const FooterHome = () => (
    <footer className="footer_page-home">
        <div className="container">
            <div className="grid-footer grid grid-column-2">
                <div className="numbers align-self-center flex justify-content-around flex-wrap">
                    
                    <WppNumberSP/>
                    <WppNumberRS/>  
                    <WppNumberSC/>
                    <WppNumberPR/>
                    
                </div>
                <SocialMedia className="justify-self-center justify-content-center align-self-center" />
            </div>
        </div>
        
    </footer>
);

export default FooterHome;