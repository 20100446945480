import React from 'react';
import './socialMedia.scss';

import FacebookImg from '../../assets/images/facebook.png';
import InstagramImg from '../../assets/images/instagram.png';

const SocialMedia = ({className, elementInTop}) => (
    <div className={`social-media flex flex-wrap ${className}`}>
        {elementInTop !== undefined && <div className="elementInTop">{elementInTop}</div>}
        <p className="text-center">Nos acompanhe também nas redes sociais!</p>
        <div className="medias">
            <a target="_blank" rel="noopener noreferrer"  href="https://www.facebook.com/gettrackrastreamento"><img src={FacebookImg} alt="facebook"/></a>
            <a target="_blank" rel="noopener noreferrer"  href="http://instagram.com/gettrackoficial/"><img src={InstagramImg} alt="instagram"/></a>
        </div>
    </div>
);

export default SocialMedia;