import React from 'react';
import './screenApp.scss';

const ScreenApp = ({image, text}) => (
    <div className="screen-app flex flex-wrap justify-content-center">
        <img src={image} alt="screen"/>
        <p>{text}</p>
    </div>
);

export default ScreenApp;