import React from 'react'
import './style.scss'

import carroImg from '../../assets/images/CARRO.png'
import cargasImg from '../../assets/images/CARGAS-EM-GERAL.png'
import motoImg from '../../assets/images/MOTO.png'
import machineImg from '../../assets/images/machine.png'
import caminhaoImg from '../../assets/images/truck.png'
import locadorasImg from '../../assets/images/LOCADORAS.png'



const ApresentationImages = () => (
    <div className="apresentation-images">
        <img className="apresentation-images-item" src={carroImg} alt="Carro" />
        <img className="apresentation-images-item" src={cargasImg} alt="Cargas" />
        <img className="apresentation-images-item" src={motoImg} alt="Moto" />
        <img className="apresentation-images-item" src={machineImg} alt="machine" />
        <img className="apresentation-images-item" src={caminhaoImg} alt="truck" />
        <img className="apresentation-images-item" src={locadorasImg} alt="Locadoras" />
    </div>
)


export default ApresentationImages