import React, { useState, useRef } from 'react';
import './contact.scss';

import NavBar from '../../components/navBar/';
import Button from '../../components/btnGlobal/';
import FooterContact from '../../components/footers/footerContact/';
import InputMask from "react-input-mask";



const Contact = () => {
    const [sending, setSending] = useState();
    const [emailError, setEmailError] = useState();
    const [nameError, setNameError] = useState();
    const [phoneError, setPhoneError] = useState();

    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const phoneRef = useRef(null)
    const messageRef = useRef(null)

    const printErros = () => (
        nameError ? <div className="errors">{nameError}</div> :
        emailError ? <div className="errors">{emailError}</div> :
        phoneError ? <div className="errors">{phoneError}</div> : (null)       
    )

    
    const handleSubmit = () => {
        const form = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            phone: phoneRef.current.value,
            message: messageRef.current.value
        }


        if (form.name.length < 3)  {
            setNameError('Nome inválido');
            return;
        } else {
            setNameError(null);
        }

        if (form.email.length < 3) {
            setEmailError('Email inválido');
            return;
        } else {
            setEmailError(null);
        }

        if (form.phone.length < 13) {
            setPhoneError('Telefone inválido');
            return;
        } else {
            setPhoneError(null);
        }


        setSending(true);

        fetch('https://getrastreamento.com.br/gettrack-website-mailer/sendmail.php', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(form),
        })
        .then(data => data.ok && data.json())
        .then(response => {
            if (response.success) {
                setSending(false);
                nameRef.current.value = ""
                emailRef.current.value = ""
                phoneRef.current.value = ""
                messageRef.current.value = ""
                
                alert("Você enviou o e-mail com sucesso!")
                
            }
        });
    }

    return (
        <div className="page-contact">
            <NavBar classHeader="mbot-low"/>
            <div className="container">
                <div className="contact">
                    <div className="contact-header">
                        <span>Deixe seus dados para que um dos nossos consultores entrar em contato com você para tirar todas as suas dúvidas e enviar cotação.</span>
                    </div>
                    <div className="contact-body">
                        <div className="contact-input">
                            <label htmlFor="name">Nome completo*</label>
                            <input ref={nameRef} id="name" />
                        </div>
                        <div className="contact-input">
                            <label htmlFor="email">E-mail*</label>
                            <input ref={emailRef} id="email"/>
                        </div>
                        <div className="contact-input">
                            <label htmlFor="phone">Telefone*</label>
                            <InputMask mask="(99) 9 9999-9999" ref={phoneRef} id="phone" />
                        </div>
                        <div className="contact-input">
                            <label htmlFor="email">Mensagem</label>
                            <textarea ref={messageRef} rows="4" cols="100" id="email" />
                        </div>
                    </div>
                    <div className="contact-actions">
                        <Button text="Solicite cotação" btnStyle="highlight" onClick={sending ? null : handleSubmit} />
                    </div>
                </div>
                {printErros()}
            </div>
            <FooterContact />
        </div>
    )
}

export default Contact;